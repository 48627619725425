.courseGrid {
  display: grid;
  width: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  grid-column-gap: 50px;
  grid-row-gap: 25px;
  padding: 50px 25px 25px 25px;
  // margin: auto;
}

a:hover {
  all: unset;
}


.introCoursesWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 5rem;
  // padding: 2rem;
  font-family: sans-serif;
}

.introCoursesHeader {
  // display: grid;
  // grid-column: 1 / -1;
  color: black;
  text-align: left;
  margin-bottom: 2.5rem;
  padding-left: 4rem;
}

.courseNavBar {
  color: #697586;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1rem;
    text-decoration: none;
    border: none;
    flex-wrap: wrap;
    gap: 0.25rem;
 
}

.courseNavBarItem {
  all: unset;
    color: #697586;
    border: 1px solid transparent;
    background-color: rgb(207, 207, 207);
    ;
    border-radius: 0.25rem;
    padding: 0.25rem;
  
    &:hover {
      // text-decoration: underline;
      color: black;
      cursor: pointer;
      border: 1px solid black;
    }
}

// .courseNavBarItem:hover {
//   color: black;
//   cursor: pointer;
// }

.courseNavBarItemHighlighted {
  // text-decoration: underline;
  // color: black;
  // text-decoration: underline;
    color: black;
    border: 1px solid darkgray;
  
    &:hover {
      border: 1px solid black;
    }
}

hr {
  border-top: solid darkgray 2px;
  width: 100%;
}

.introCourseStatement {
  display: grid;
  grid-column: 1 / -1;
  color: #697586;
  text-align: left;
  margin-bottom: 2.5rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.courseTopicWrapper {
  // display: grid;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 15rem;
  width: 15rem;
  padding: 15px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: center;
  color: black;
  background-color: white;
  text-align: left;
  margin: auto;
}

.courseTopicWrapper:hover {
  border: solid black 1px;
}

.courseImage {
  margin-bottom: 1rem;
  height: 110px;
}

.courseText {
  font-size: .75rem;
  // white-space: nowrap;
  color: #697586;
  // width: fit-content;
}

.courseTopic {
  font-size: .9rem;
  font-weight: bold;
  padding-top: .5rem;
  padding-bottom: .5rem;

}

.courseItemWrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;
  width: 15rem;
  height: 15rem;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  background-color: white;
  border: 4px solid #1dc5d9;
  border-radius: 50%;
}

.courseItemText {
  display: block;
  color: black;
  font-weight: bold;
}

.learningItHeaderCourses {
  width: 90%;
  display: grid;
  grid-column: 1 / -1;
  margin: auto;
  margin-bottom: 2rem;
}

//**************************************************//

@media (max-width: 1170px) {
  .courseGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

//**************************************************//

@media (max-width: 600px) {
  .courseGrid {
    display: grid;
    grid-template-columns: 1fr;
  }
}
