@import "variables";



// .aboutUsWrapper {
//   width: 80%;
//   background-color: rgb(241, 239, 239);
//   margin: auto;
//   margin-top: 7rem;
//   padding: 1rem;
//   font-family: "Poppins", sans-serif;
  
// }

.aboutUsHeader {
  grid-column: 1 / 4;
  width: 100%;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  // font-family: $open-sans;
  margin: auto;
  margin-top: 7rem;
  margin-bottom: 3rem;
  background-color: #0a6b7c;
  text-align: center;
}

.aboutUsItemWrapper {
  display: grid;
  grid-template-columns: 1fr 2fr ;
  gap: 3rem;
  // margin: auto;
  padding: 4rem;
  padding-top: 6rem;
  justify-content: center;
  align-items: center;
  // align-items: start;
  

.aboutUsColumn1 {
  display: grid;
  grid-column: 1 / 2;
  // margin: auto;
  text-align: left;
}

.aboutUsColumn2 {
  display: grid;
  grid-column: 2 / 3;
  margin: auto;
}

// .aboutUsItemText {
//   display: grid;
//   grid-column: 2 / 3;
//   // margin: auto;
//   padding: 1rem;
//   font-size: .85rem;
//   border-radius: 5px;
//   }

   
}

.aboutUsItemMember {
  color: black;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.aboutUsItemTitle {
  color: black;
  margin-bottom: 1rem;
}

.aboutUsSpacer {
  margin-bottom: 5rem;
}

.linkedIn {
  width: 1rem;
  margin-left: .5rem;
}

.aboutUsItemPic {
  border-radius: 5px;
  max-width: 15rem;
}

hr {
  border-top: 1px solid black;
 
  

}

@media (max-width: 1250px) {

  

    .aboutUsColumn1 {
      display: grid;
      grid-column: 1 / 4;
      margin: auto;
      text-align: left;
      vertical-align: top;
    }
  
    .aboutUsItemText {
      display: grid;
      grid-column: 1 / 4;
      margin: auto;
      // border: 1px solid black;
      padding: 1rem;
      font-size: .85rem;
      border-radius: 5px;
      vertical-align: top;
    }

    
}

@media (max-width: 600px) {

  // .aboutUsWrapper {
  //     width: 100%;
  //     background-color: rgb(241, 239, 239);
  //     margin: auto;
  //     margin-top: 3rem;
  //     padding: 0rem;
  //     font-family: "Poppins", sans-serif;
  
  //   }

  .aboutUsItemWrapper {
      display: grid;
        grid-template-columns: 1fr ;
        gap: 3rem;
        // margin: auto;
        padding: 2rem;
        padding-top: 6rem;
        justify-content: center;
        align-items: center;
  
  
      .aboutUsColumn1 {
        text-align: left;
        display: grid;
        grid-column: 1 / 2;
      }
  
      .aboutUsItemText {
        display: grid;
        grid-column: 2 / 4;
        padding: 1rem;
        font-size: .85rem;
        border-radius: 5px;
      }
  
      // .aboutUsItemText {
      //   display: grid;
      //   grid-column: 2 / 4;
      //   padding: 1rem;
      //   font-size: .85rem;
      //   border-radius: 5px;
      // }

      .aboutUsColumn2 {
        display: grid;
        grid-column: 1 / 2;
      }
    }
}
