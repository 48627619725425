.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: black;
  color: white;
  flex-wrap: wrap;
  gap: 1rem;
}

.copyrightLink {
  all: unset;
  color: white;
  font-size: 0.75rem;
}

.copyrightLink:hover {
  all: unset;
  font-size: 0.75rem;
}

.footerLink {
  all: unset;
  color: white;
  font-size: 0.75rem;
}

.footerLink:hover {
  all: unset;
  font-size: 0.75rem;
  text-decoration: underline;
  cursor: pointer;
}

.linkedInFooter {
  width: 1.5rem;
}
