.clientsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  // margin-bottom: 4rem;
  font-family: sans-serif;
  background-color: black;
}

.block1 {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 100%;
  height: 100%;
  height: 45rem;
  background-color: black;
  margin-top: 3rem;
  text-align: left;
}

.block1Left {
  grid-column: 1 / 2;
  padding: 4rem;
}

.block1Right {
  grid-column: 2 / 3;
  width: 100%;
  background-image: url("../images/Elipsis.svg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  margin-top: -10rem;
  margin-right: -5rem;
}

.productImage1 {
  position: absolute;
  top: 200px;
  left: 550px;
  // width: 35rem;
}

.productImage2 {
  position: absolute;
  top: 250px;
  left: 675px;
  rotate: -10deg;
  // width: 35rem;
}

.block1Header {
  padding-top: 4rem;
  max-width: 6ch;
  font-weight: 900;
  font-size: 3rem;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.block1Text {
  max-width: 45ch;
  padding-top: 4rem;
  font-size: 1rem;
}

.demoButton {
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  margin-top: 4rem;
}

.anchor:hover {
  text-decoration: none;
  cursor: pointer;
}

.anchor {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.elipsis {
  margin: auto;
  opacity: 0.7;
  // max-width: 60rem;
  // padding-left: 10rem;
}

//Block2//

.block2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  justify-content: space-around;
  grid-column: 1 / 3;
  object-fit: cover;
  color: white;
  background-color: black;
  background-image: url("../images/block3.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  text-align: left;
  padding: 3rem;
  padding-bottom: 6rem;
  justify-content: space-around;
  margin-top: -2rem;
  // height: 47rem;
}

.block2Header {
  grid-row: 1 / 2;
  grid-column: 1 / 4;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
  font-size: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;
}

.block2ItemWrapper {
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.block2ItemWrapper1 {
  grid-row: 2 / 3;
}

.block2ItemWrapper2 {
  grid-row: 2 / 3;
}

.block2ItemWrapper3 {
  grid-row: 2 / 3;
}

.block2Icon {
  width: 80px;
  padding-bottom: 1.5rem;
}

.block2Line {
  border-top: 2px solid gray;
  width: 100%;
}

.block2ItemTitle {
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
  font-size: 1.25rem;
  padding-bottom: 2rem;
  max-width: 28ch;
}

.block2ItemText {
  color: rgb(169, 169, 169);
  font-size: 1rem;
  max-width: 38ch;
}

//Block3//

.block3 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
}

.block3Left {
  display: grid;
  background-color: white;
  grid-column: 1 / 2;
  color: black;
  padding: 3rem;
  // padding-top: 5rem;
  justify-content: center;
  align-items: center;
  
}

.block3LeftTitle {
  padding-left: 2rem;
  padding-bottom: 2rem;
  font-weight: bolder;
  font-size: 2rem;
}

.emphasisUnderline {
  background-image: linear-gradient(
    90deg,
    rgba(14, 146, 220, 1),
    rgba(14, 146, 220, 0.15)
  );
  background-size: 100% 4px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding-bottom: 2px;
  /* Pull underline down away from bottom of text */
}

.block3Right {
  background-color: white;
  grid-column: 2 / 3;
  padding: 3rem;
  // padding-top: 5rem;
  color: black;
  justify-content: center;
  
}

.block3RightItemWrapper {
  display: flex;
  flex-direction: row;
  margin: 0;
  align-items: start;
}

.block3RightHeader {
  font-weight: bolder;

  font-size: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 6rem;
}

.block3RightSubHeader {
  color: rgb(106, 106, 106);
  max-width: 50ch;
  padding-bottom: 2rem;
}

.block3RightTextBold {
  font-weight: bolder;
}

.block3RightText {
  max-width: 50ch;
}

.homeIcon {
  width: 15px;
  margin-right: 0.5rem;
  padding-top: 0.25rem;
}

.block3Image {
  max-width: 38rem;
}

//Block4//

.block4 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  column-gap: 3rem;
}

.block4TopLeft {
  display: grid;
  background-color: black;
  color: white;
  grid-column: 1 / 2;
  justify-content: center;
    align-items: center;
    padding: 1rem;
}

// .block4TopLeftWrapper {
//   padding: 2rem;
// }

.block4TopLeftTitle {
  font-weight: bolder;
  // padding-bottom: 3rem;
  font-size: 2rem;
}

.block4TopRight {
  background-color: black;
  grid-column: 2 / 3;
  padding: 3rem;
  padding-top:11rem;
  color: white;
  justify-content: center;
  align-items: center;
}
.block4TopRightWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block4TopRightItemWrapper {
  display: flex;
  flex-direction: row;
  margin: 0;
  align-items: start;
}

.block4TopRightHeader {
  font-weight: bolder;
  font-size: 1.25rem;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  max-width: 25ch;
  // margin-right: 6rem;
}

.block4TopRightText {
  max-width: 40ch;
  font-size: 1rem;
}

//Block5//

.block5 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}

.block5Right {
  // grid-column: 2 / 3;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // background-color: black;
  // color: white;
  display: grid;
    background-color: black;
    color: white;
    grid-column: 2 / 3;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.block5Left {
  grid-column: 1 / 2;
  background-color: black;
  padding: 3rem;
  color: white;
  justify-content: center;
}

.block5Images {
  max-width: 40vw;
}

////////
.scrollOffset5 {
  scroll-margin-top: 5rem;
}

//block6//

.block6 {
  grid-column: 1 / 3;
  width: 100%;
  height: 100%;
  // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.87), rgba(27, 44, 81, 0.87), rgba(65, 17, 107, 0.87) );
  min-height: 20rem;
  background-image: url(../images/ClientsBlock6Background.png);
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem;
}

.block6Header {
  padding-top: 2rem;
  // max-width: 6ch;
  font-weight: 900;
  font-size: 3rem;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.block6Text {
  max-width: 45ch;
  padding-top: 4rem;
  font-size: 1rem;
}

.block6DemoButton {
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  margin-top: 2rem;
  margin-right: 3rem;
  float: right;
}

.demoButtonConstant {
  position: fixed;
  right: 0;
  top: 185px;
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  transform: rotate(270deg);
  margin-right: -4.3rem;
}

@media only screen and (max-width: 600px) {

  .block1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    height: 100%;
    height: 32rem;
    background-color: black;
    margin-top: -1rem;
    text-align: left;
    margin-bottom: 1rem;
  }

  .block1Left {
    grid-row: 1 / 2;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    height: 0rem;
  }

  .block1Right {
    display: grid;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -10rem;
    margin-right: -5rem;
  }

  .productImage1 {
    position: absolute;
    top: 300px;
    left: 60px;
    width: 15rem;
  }

  .productImage2 {
    position: absolute;
    top: 350px;
    left: 95px;
    rotate: -10deg;
    width: 15rem;
  }

  .block1Header {
    font-size: 1.5rem;
  }

  .block1Text {
    padding-top: 1rem;
    font-size: 0.85rem;
  }

  

  .demoButton {
    width: 8rem;
    font-size: 1rem;
    margin-top: 2rem;
    z-index: 99999;
    display: none;
  }


  //Block2Mobile//

  .block2 {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    object-fit: cover;
    color: white;
    background-color: black;
    padding: 0 2rem;
    background-image: unset;
    // background-image: url("../images/block3.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // text-align: left;
    // padding: 3rem;
    // padding-bottom: 6rem;
    // margin-top: -2rem;
    // margin-top: 4rem;
    // height: 92rem;
    
  }

  .block2Header {
    // grid-row: 1 / 2;
    // grid-column: 1 / 4;
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
    font-size: 1.25rem;
    // padding-left: 2rem;
    // padding-top: 2rem;
  }

  .block2ItemWrapper {
    // display: flex;
    // flex-direction: column;
    align-items: left;
    padding: 2rem;
    padding-top: 0rem;
  }

  .block2ItemWrapper1 {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
  }

  .block2ItemWrapper2 {
    grid-column: 1 / 4;
    grid-row: 3 / 4;
  }

  .block2ItemWrapper3 {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    padding-bottom: 2rem;
  }

  .block2Icon {
    width: 50px;
    // padding-bottom: 1.5rem;
  }

  // .block2Line {
  //   border-top: 2px solid gray;
  // }

  .block2ItemTitle {
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
    // font-size: 1rem;
    padding-bottom: 2rem;
    // max-width: 28ch;
  }

  .block2ItemText {
    // color: rgb(169, 169, 169);
    font-size: 0.85rem;
    // max-width: 38ch;
  }

  //Block3Mobile//

  .block3Left {
    display: grid;
    justify-content: center;
    align-content: center;
    // background-color: white;
    grid-column: 1 / 3;
    // color: black;
    // padding: 3rem;
    padding-top: 2rem;
    margin: auto;
    width: 100%;
  }

  .block3LeftTitle {
    padding-left: 0rem;
    padding-bottom: 2rem;
    // font-weight: bolder;
  }

  // .emphasisUnderline {
  //   background-image: linear-gradient(
  //     90deg,
  //     rgba(14, 146, 220, 1),
  //     rgba(14, 146, 220, 0.15)
  //   );
  //   background-size: 100% 4px;
  //   background-repeat: no-repeat;
  //   background-position: left bottom;
  //   padding-bottom: 2px;
  //   /* Pull underline down away from bottom of text */
  // }

  .block3Right {
    // background-color: white;
    grid-column: 1 / 3;
    padding: 2rem;
    padding-top: 0;
    margin: auto;
    // padding-top: 5rem;
    // color: black;
    // justify-content: center;
  }

  // .block3RightItemWrapper {
  //   display: flex;
  //   flex-direction: row;
  //   margin: 0;
  //   align-items: start;
  // }

  .block3RightHeader {
    // font-weight: bolder;
    font-size: 1.25rem;
    // padding-bottom: 1.5rem;
    padding-top: 0rem;
  }

  .block3RightSubHeader {
    // color: rgb(106, 106, 106);
    max-width: 40ch;
    // padding-bottom: 2rem;
    font-size: 1rem;
  }

  .block3RightTextBold {
    // font-weight: bolder;
    font-size: 0.85rem;
  }

  .block3RightText {
    max-width: 40ch;
    font-size: 0.85rem;
  }

  // .homeIcon {
  //   width: 15px;
  //   margin-right: 0.5rem;
  //   padding-top: 0.25rem;
  // }

  .block3Image {
    min-width: 18rem;
    max-width: 18rem;
  }

  .block3RightItemWrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }

    //Block4//
  
    .block4 {
      display: grid;
      grid-column: 1 / 3;
      justify-items: center;
      align-items: center;
      justify-content: center; 
    }
  
    .block4TopLeft {
      display: grid;
      justify-content: center;
      align-content: center;
      justify-items: center;
      align-items: center;
      background-color: black;
      color: white;
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
  
    .block4TopLeftTitle {
      font-weight: bolder;
      padding-bottom: 3rem;
      font-size: 2rem;
    }
  
    .block4TopRight {
      display: grid;
      background-color: black;
      grid-column: 1 / 3;
      padding: 3rem;
      padding-top: 0rem;
      color: white;
      justify-content: center;
      justify-items: center;
      align-items: center;
    }
  
    .block4TopRightWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      align-items: center;
    }
  
    .block4TopRightItemWrapper {
      display: flex;
      flex-direction: row;
      margin: 0;
      align-items: start;
    }
  
    .block4TopRightHeader {
      font-weight: bolder;
      font-size: 1.25rem;
      padding-bottom: 1.5rem;
      padding-top: 2rem;
      max-width: 25ch;
      // margin-right: 6rem;
    }
  
    .block4TopRightText {
      max-width: 40ch;
      font-size: 1rem;
    }
  
    //Block5//
  
    .block5 {
      display: grid;
      grid-column: 1 / 3;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      max-height: 43rem;
      justify-items: center;
      align-items: center;
    }
  
    .block5Right {
      display: grid;
        justify-content: center;
        align-content: center;
        justify-items: center;
        align-items: center;
        background-color: black;
        color: white;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
       
    }
  
    .block5Left {
      display: grid;
        background-color: black;
        grid-column: 1 / 3;
        padding: 3rem;
        padding-top: 0rem;
        color: white;
        justify-content: center;
        justify-items: center;
        align-items: center;
    }
  
    .block5Images {
      min-width: 18rem;
    }
  
    ////////
    .scrollOffset5 {
      scroll-margin-top: 5rem;
    }

    //block6Mobile//
  
    .block6 {
      grid-column: 1 / 3;
      width: 100%;
      height: 100%;
      // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.87), rgba(27, 44, 81, 0.87), rgba(65, 17, 107, 0.87) );
      min-height: 20rem;
      background-image: url(../images/ClientsBlock6Background.png);
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 3rem;
    }
  
    .block6Header {
      padding-top: 2rem;
      // max-width: 6ch;
      font-weight: 900;
      font-size: 1.5rem;
      // text-shadow: 0px 1px, 1px 0px, 1px 0px;
    }
  
    .block6Text {
      max-width: 45ch;
      padding-top: 4rem;
      font-size: 1rem;
      margin-top: 3rem;
    }
  
    .block6DemoButton {
      width: 11rem;
      font-size: 1.25rem;
      background-color: #0ba5ec;
      color: white;
      border: none;
      font-weight: bold;
      padding: 0.25rem;
      margin-bottom: 1rem;
      margin: auto;
      margin-top: 2rem;
      margin-right: 3rem;
      float: left;
    }
  
    .demoButtonConstant {
      width: 11rem;
      font-size: .9rem;
    }

  
}
