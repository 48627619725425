@import "variables";

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");


body {
  background-color: rgb(241, 239, 239);
}

.findItFieldSubtype {
  width: 18rem;
  top: 0rem;
  left: 0rem;
  padding: 0px 0px 35px;
}

.smallH2 {
  font-size: 1rem;
  text-align: center;
}

.smallH2D {
  padding-right: 4rem;
}

.smallH2RD {
  padding-right: 5rem;
}

.smallH2L {
  padding-right: 3rem;
  font-size: 1rem;
}

.smallH2RL {
  padding-left: 20rem !important;
  font-size: 1rem;
}

.blueH2 {
  color: $FFmidNightBlue;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.2rem;
}

.tealH2 {
  color: $FFteal;
  font-weight: bold;
}

.finalizeSolution {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

.findItQuestions {
  position: relative;
  margin-top: 1rem;
  height: 48rem;
}

.findItQuestions>div {
  position: absolute;
  margin-left: 1rem;
  margin-right: 1rem;
}

.findItDiagram {
  display: block;
  margin: auto;
  padding-top: 3rem;
  width: 100%;
}

.findingItTooltipText {
  font-size: .8rem;
}

.findingItTooltipTitle {
  font-size: 1.2rem;
}

.findingItTooltipImage {
  width: 220px;
}

#ThreadedIllustration {
  width: 100%;
  height: 100%;
}

.findItFieldThreadedDiameter {
  position: absolute;
  top: 0.25rem;
  left: 0;
  width: 14rem;
}

.findItFieldThreadPitch {
  position: absolute;
  width: 16rem;
  left: 16rem;
  top: 0.25rem;
}

.findItFieldThreadedHeadStyle {
  position: absolute;
  width: 15rem;
  right: 0.25rem;
  top: 0.25rem;
}

.findItFieldThreadedLength {
  position: absolute;
  width: 16rem;
  left: 16rem;
  top: 38rem;
}

.findItFieldThreadedMaterial {
  position: absolute;
  left: 0rem;
  top: 38rem;
  width: 14rem;
}

.findItFieldDriveType {
  position: absolute;
  width: 15rem;
  right: 0.25rem;
  top: 38rem;
}

.findItFieldThreadedTensileStrength {
  position: absolute;
  width: 20rem;
  left: 2rem;
  top: 48rem
}

.findItFieldThreadedShearStrength {
  position: absolute;
  width: 20rem;
  right: 2rem;
  top: 48rem
}

.findingItMobileImage {
  display: none;
}

/* *****************************
* Rivet fields
* *****************************/

.findItRivetDiagram {
  display: block;
  margin: auto;
  padding-top: 7rem;
  width: 100%;
}

#RivetIllustration {
  width: 100%;
  height: 100%;
}

.findItFieldRivetDiameter {
  position: absolute;
  top: 0.25rem;
  left: 0rem;
  width: 12rem;
}

.findItFieldRivetGripRange {
  position: absolute;
  top: 0;
  left: 14rem;
  width: 16rem;
}

.findItFieldRivetHeadStyle {
  position: absolute;
  width: 16rem;
  right: 0.25rem;
  top: 0.25rem;
}

.findItFieldRivetBodyMaterial {
  position: absolute;
  width: 18rem;
  top: 25rem;
  left: 5rem;
}

.findItFieldRivetStemMaterial {
  position: absolute;
  width: 18rem;
  top: 25rem;
  left: 26rem;
}

.findItFieldRivetShearStrength {
  position: absolute;
  width: 20rem;
  right: 2rem;
  top: 38rem
}

.findItFieldRivetTensileStrength {
  position: absolute;
  width: 20rem;
  left: 2rem;
  top: 38rem
}

/*******************************
* Rivet Nut Fields
********************************/

.findItRivetNutDiagram {
  padding-top: 1rem;
  width: 36rem;
}

.findItFieldRivetNutDiameterPitch {
  position: absolute;
  top: 0.25rem;
  left: 0;
  width: 14rem;
}

.findItFieldRivetNutLength {
  position: absolute;
  left: 0rem;
  width: 14rem;
}

.findItFieldRivetNutMaterial {
  position: absolute;
  width: 15rem;
  right: 0.25rem;
  top: 0.25rem;
}

.findItFieldRivetNutHeadStyle {
  position: absolute;
  width: 15rem;
  left: 0.25rem;
  top: 30rem;
}

.findItFieldRivetNutMiscFeatures {
  position: absolute;
  width: 15rem;
  right: 0.25rem;
  top: 30rem;
}

.findItFieldBodyType {
  position: absolute;
  width: 15rem;
  left: 17rem;
  top: 30rem;
}

.findItFieldRivetNutTensileStrength {
  position: absolute;
  width: 20rem;
  left: 2rem;
  top: 36rem;
}

.findItFieldRivetNutPullOutStrength {
  position: absolute;
  width: 20rem;
  right: 2rem;
  top: 36rem;
}

.findItFieldRivetNutMaxTorqueUnsupported {
  position: absolute;
  width: 20rem;
  left: 2rem;
  top: 42rem;
}

.findItFieldRivetNutMaxTorqueSupported {
  position: absolute;
  width: 20rem;
  right: 2rem;
  top: 42rem;
}

//**************************************************

.gridColumnContent {
  grid-column: content;
}

.findItRangeFieldGrid {
  display: grid;
  grid-template-columns: [label] 2.5rem [content] auto;
  grid-template-rows: [header] auto [min-row] auto [max-row] auto;
}

.findItRangeFieldLabel {
  margin: auto;
}

.unitSystemSelect {
  display: grid;
  grid-template-columns: 1fr .4fr .4fr .4fr;
  align-items: baseline;
  justify-items: center;
  padding-top: 2rem;
}

.softMetric {
  padding: 15px;
  color: $FFblu0;
  border-radius: 30px;
  display: grid;
  align-items: baseline;
  display: grid;
  grid-template-columns: 5rem 8rem;
}

.findItFooter {
  height: 5rem;
  // border-top: 1px solid $FFdkbeige;
}

.findItSubmit {
  position: relative;
  left: 36rem;
  margin-top: 8.25rem;
  background-color: $FFdarkSlate;
  border: none;
  font-size: 1.4rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.findItTopQuestions {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 10px;
  padding-top: 25px;
}

.findItBottomQuestions {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 10px;
  padding-top: 25px;
  position: absolute;
  top: 30rem;
  left: 11rem;
}

.findItFieldType {
  width: 18rem;
}

.pageHeaderFindIt {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-kerning: normal;
  font-size: 2.75rem;
  background-image: $FFfindItHeaderGrad;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top-right-radius: 25px;
  width: 55rem;
}

.findIt {
  background-color: white;
  min-width: 55rem;
  border-radius: 0px 0px 25px 25px;
  padding: 2rem;
  height: 78rem;
}

.navLogo {
  width: 75px;
  height: 1 rem;
  padding-right: 0.5rem;
}

#findItContainer {
  margin-top: 6rem;
  width: 55rem;
}

.findItH2 {
  padding-bottom: 1rem;
  border-bottom: 1px solid $FFdkbeige;
  font-size: 1.4rem;
}

.navLink {
  color: white !important;
  font-size: 1.2rem;
  margin-right: 3rem;
  margin-left: 1rem;
}

.reactSwitchBg {
  background-color: $FFsteelBlue !important;
}

.cssG1d714ValueContainer {
  border: solid lightgray;
}

.cssTlfeczIndicatorContainer {
  border: solid lightgray;
}

.fieldIcon {
  width: 4rem;
  margin: auto;
  grid-area: icon;
}

.findingItFieldContent {
  grid-area: content;
}

.findingItFieldContent h2 div {
  display: inline;
}

.findingItFieldContainerWithIcon {
  display: grid;
  gap: 0.5rem;
}

.findingItFieldContainerWithIconLeft {
  grid-template-columns: [icon] 4rem [content] auto;
}

.findingItFieldContainerWithIconRight {
  grid-template-columns: [content] auto [icon] 4rem;
}

.findingItFieldHeaderIcon {
  height: 1em;
  margin-top: -0.2em;
}

// ***************************
// Find It Results Card
// ***************************

.findItResultsCard {
  background-color: white;
  border-radius: 25px;
  padding: 40px;
  width: 55rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.solutionContactEmailText {
  font-size: 1.3rem;
  font-weight: bold;
  padding: 1rem;
}

.findItResultsMessage {
  margin-bottom: 25px;
}

.solutionPreviewHeader {
  display: grid;
  grid-template-columns: 80% 20%;
}

.findItResults>h2 {
  position: relative;
  display: grid;
  grid-template-columns: 80% 20%;
}

.findItResultsNumber {
  align-content: right;
  display: inline;
  font-weight: normal;
  font-style: italic;
  color: $FFdkdkbeige;
  text-align: right;
  font-size: 70%;
  vertical-align: middle;
}

.findItResultEntry {
  background-color: $FFltbeige;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  color: $FFblu1;
  padding: 35px;
  margin-bottom: 15px;
  border-radius: 25px;
  position: relative;
}

.viewSourceButton {
  font-weight: bold;
  font-size: 0.7rem;
  color: white;
  background-color: $FFred;
  padding: 3px 7px 3px 7px;
}

.findItResultsDetails {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 10px;
  background-color: #eef1e257;
  border-radius: 0.8rem;
  border: 2px solid;
  padding: 1rem;
}

.firdText {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 10px;
  align-items: baseline;
  list-style-position: inside;
  list-style-type: none;
  margin-bottom: 0;
}

.firdText>dt {
  color: #052c45;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: left;
  vertical-align: text-bottom;
}

.firdText>dd {
  font-size: 1.1rem;
  font-weight: normal;
  color: black;
}

.findItResultImage {
  width: 100%;
  padding: 10px;
  object-fit: contain;
  mix-blend-mode: multiply;
}

.findItResultsOptions {
  width: 95%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-column-gap: 10px;
  padding-top: 25px;
}

.findItSolutionContact {
  width: auto;
  display: grid;
  grid-template-rows: auto auto;
  margin-top: 2rem;
}

.findItSolutionContactFields label {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-weight: bold;
}

.findItSolutionContactFields input {
  width: 25rem;
}

.findItSolutionContactFields .reactSelect {
  width: 14rem;
}

.findItSolutionContactFields textarea {
  display: block;
  width: 100%;
}

.findItSolutionContactFields>h2 {
  font-weight: bold;
}

.findItSolutionContact>Button {
  max-width: 10rem;
  margin: 1rem;
}

.findingItSummaryHeader {
  font-size: 1.7rem;
  margin-bottom: 1.5rem;
}

.findItSolutionBreak {
  display: none;
}

.tooltipProTip {
  font-weight: bold;
  font-style: normal;
}

// .headStyle {
//   position: absolute;
//   left: 70%;
//   top: 30%;
//   background-color: rgba(100, 100, 0, 0.3);
// }

// .questionGroup {
//   background-color: #d6dbc2;
//   border-radius: 0px 40px 40px 40px;
//   padding: 3rem;
//   margin: 0 auto;
//   margin-top: 1.4rem;
//   position: relative;
//   font-weight: bold;
//   color: #327c9b;
// }

// .questionGroup > h2 {
//   color: white;
//   background-color: #8c221b;
//   padding: 0.7rem 1rem 0.7rem 1rem;
//   border-radius: 25px;
//   position: absolute;
//   top: -1.5rem;
//   left: 0;
// }

.questionGroup > label {
  font-weight: bold;
  color: #327c9b;
}

.questionGroup > div > label {
  font-weight: bold;
  color: #327c9b;
}

// TODO: These should be merged with same from other files... later
.feature-header {
  font-kerning: normal;
  font-size: 2.75rem;
  background-image: $FFgrad1;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top-right-radius: 25px;
  position: relative;
  display: flex;
}

.fes-branded-header {
  font-family: "Oswald", "sans-serif";
}

.fes-branded-trademark {
  vertical-align: super;
  font-size: 50%;
  color: white;
}


//mobileview//

@media (max-width: 600px) {
  #findItContainer {
    padding-top: 2rem;
    width: 100%;
    margin: auto;
    margin-top: 3rem;
  }

  .findIt {
    min-width: 100%;
    height: 145rem;
    margin: auto;
  }

  .findingItHeader {
    width: 100%;
  }

  .unitSystem {
    position: absolute;
    top: 27rem;
    left: -4rem;
  }

  .unitSystemSelect {
    position: absolute;
    top: 27.5rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 10rem;
  }

  .unitSystemLabel {
    grid-column: 1 / 4;

  }

  .findItFooter {
    border-top: 0px;
  }

  .formText {
    padding-left: 5px;
    padding-right: 5px;
  }

  h2 {
    width: 18rem;

  }

  .findItDiagram {
    width: 20rem;
    margin: auto;
    margin-top: 21rem;
    margin-bottom: 40rem;
    float: left;
    display: none;
  }

  .findingItMobileImage {
    display: block;
    width: 200px;
    height: 150px;
    margin: auto;
  }

  .findItFieldThreadedDiameter {
    position: absolute;
    top: 9rem;
    left: 0;
    width: 16rem;
  }

  .findItFieldThreadedLength {
    position: absolute;
    top: 25rem;
    left: 0rem;
    width: 16rem;
  }

  .findItFieldThreadPitch {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 41rem;
  }

  .findItFieldThreadedHeadStyle {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 56rem;
  }

  .findItFieldDriveType {
    position: absolute;
    width: 15rem;
    left: 0rem;
    top: 63rem;
  }

  .findItFieldThreadedMaterial {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 78rem;
  }

  .findItFieldThreadedTensileStrength {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 88rem;
  }

  .findItFieldThreadedShearStrength {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 103rem;
  }

  .fieldIcon {
    display: none;
  }

  .findItSubmit {
    position: absolute;
    left: 4.5rem;
    top: 140rem;
  }

  /* *****************************
  * Rivet fields mobile
  * *****************************/

  .findItFieldRivetDiameter {
    position: absolute;
    top: 9rem;
    left: 0rem;
    width: 16rem;
  }

  .findItFieldRivetGripRange {
    position: absolute;
    top: 24rem;
    left: -1.7rem;
    width: 16rem;
    margin: auto;
  }

  .findItFieldRivetHeadStyle {
    position: absolute;
    width: 16rem;
    top: 42rem;
    left: 0rem;
  }

  .findItFieldRivetBodyMaterial {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 58rem;
  }

  .findItFieldRivetStemMaterial {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 77rem;
  }

  .findItFieldRivetShearStrength {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 92rem;
    margin: auto;
  }

  .findItFieldRivetTensileStrength {
    position: absolute;
    width: 16rem;
    left: 0rem;
    top: 108rem;
    margin: auto;
  }

  // ***************************
  // Find It Results Card Mobile
  // ***************************

  .findItResultsCard {
    max-width: 22.5rem;
    margin-bottom: 2rem;
  }

  .findItResultsMessage {
    margin-bottom: 25px;
  }

  .solutionPreviewHeader {
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .findItResults>h2 {
    position: relative;
    display: grid;
    grid-template-columns: 80% 20%;
  }

  .findItResultsNumber {
    align-content: right;
    display: inline;
    font-weight: normal;
    font-style: italic;
    color: $FFdkdkbeige;
    text-align: right;
    font-size: 70%;
    vertical-align: middle;
  }

  .findItResultEntry {
    background-color: $FFltbeige;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    color: $FFblu1;
    padding: 35px;
    margin-bottom: 15px;
    border-radius: 25px;
    position: relative;
  }

  .viewSourceButton {
    font-weight: bold;
    font-size: 0.7rem;
    color: white;
    background-color: $FFred;
    padding: 3px 7px 3px 7px;
  }

  .fIRDtext {
    display: grid;
    grid-template-columns: 100% 0%;
    grid-column-gap: 10px;
    align-items: baseline;
    list-style-position: inside;
    list-style-type: none;
    margin-bottom: 0;
  }

  .fIRDtext>dt {
    color: #052c45;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    vertical-align: text-bottom;
  }

  .fIRDtext>dd {
    font-size: 0.8rem;
    font-weight: normal;
    color: black;
  }

  .findItResultImage {
    display: none;
  }

  .findItResultsOptions {
    width: 95%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-column-gap: 10px;
    padding-top: 25px;
  }

  .findItSolutionContact {
    width: auto;
    display: grid;
    grid-template-rows: auto auto;
    margin-top: 2rem;
  }

  .findItSolutionContactFields label {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
  }

  .findItSolutionContactFields input {
    width: 25rem;
  }

  .findItSolutionContactFields .reactSelect {
    width: 14rem;
  }

  .findItSolutionContactFields textarea {
    display: block;
    width: 100%;
  }

  .findItSolutionContact>Button {
    max-width: 10rem;
    margin: 1rem;
  }

  .findingItSummaryHeader {
    font-size: 1.8rem;

  }

  .viewSourceButton {
    max-width: 9rem;
    font-size: 0.7rem;
  }

  .solutionContactEmailText {
    font-size: 1rem;
    max-width: 30ch;
  }

  .findItSolutionContactFields input {
    width: 15rem;
  }

  .findItSolutionBreak {
    display: block;
  }
}