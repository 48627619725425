$FFrainbow: linear-gradient(to right, #582b7d, #582b7d, #3c649b, #3c649b);

.missionStatementWrapper {
  width: 100%;
  height: 100vh;
  background-size: auto auto;
  color: white;
  // margin-top: 3rem;
  object-fit: cover;
  background-position: right;
  background-color: black;
  background-image: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 10rem,
      rgba(0, 0, 0, 1) 45rem
    ),
    url("../images/Maze3.png");
  background-repeat: no-repeat;

  padding-top: 5rem;
  font-family: sans-serif;
  // max-height: 42rem;
}

.missionItemWrapper {
  color: white;
  padding: 1rem;
  max-width: 55ch;
  padding: 1rem;
  padding-left: 6rem;
}

.missionTitle {
  font-weight: bolder;
  padding-bottom: 1rem;
}

//**************************************************//

@media only screen and (max-width: 600px) {
  .missionStatementWrapper {
    background-color: black;
    background-image: none;
    padding-top: 2rem;
  }

  .missionItemWrapper {
    color: white;
    padding: 1rem;
    max-width: 55ch;
  }

  .missionTitle {
    font-weight: bolder;
    padding-bottom: 1rem;
    font-size: 1.5rem;
  }
}
