@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

$FFrainbow: linear-gradient(
  to right,
  #052c45,
  #052c45,
  #582b7d,
  #582b7d,
  #0a6b7c,
  #3c649b,
  #3c649b
);

$FESUnderline: linear-gradient(to right, #0ba5ec, #0ba5ec, #0ba5ec, white);

.wrapper {
  display: grid;
  background-color: black;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: auto;
  // margin-bottom: 2rem;
  font-family: sans-serif;
  // max-width: 1366px;
}

.demoButtonConstant {
  position: fixed;
  right: 0;
  top: 185px;
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  transform: rotate(270deg);
  margin-right: -4.3rem;
  z-index: 9999;
}

//Hubspot//

// .hbsptWrapper {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   background-color: black;
//   margin-top: 1rem;
//   padding-bottom: 1rem;
//   width: 100%;
//   height: 100%;
//   background-image: linear-gradient(
//       270deg,
//       rgba(0, 0, 0, 0) 10rem,
//       rgba(0, 0, 0, 1) 45rem
//     ),
//     url("../images/Maze3.png");
//   background-repeat: no-repeat;
//   object-fit: cover;
//   background-position: top right;
// }

.hubspotForm {
  background-image: linear-gradient(270deg,
        rgba(0, 0, 0, 0) 10rem,
        rgba(0, 0, 0, 1) 45rem),
      url("../images/Maze3.png");
    background-repeat: no-repeat;
    object-fit: cover;
    background-position: top right;
    background-color: black;
  // grid-column: 1 / 2;
  padding: 3rem;
  // min-width: 48rem;
  // min-height: 60rem;
  // padding-left: 2rem;
}

// .hbsptBackground {
//   grid-column: 2 / 3;
//   // background-image: linear-gradient(270deg,
//   //     rgba(0, 0, 0, 0) 10rem,
//   //     rgba(0, 0, 0, 1) 45rem),
//   //   url("../images/Maze3.png");
//   // background-repeat: no-repeat;
//   // background-size: auto auto;
//   // object-fit: cover;
//   opacity: 0.5;

//   // padding-left: 5rem;
// }

///////////////////////////////

.anchor:hover {
  text-decoration: none;
  cursor: pointer;
}

//Block1//

// .block1 {
//   grid-column: 1 / 3;
//   background-size: auto auto;
//   color: white;
//   margin-top: 3rem;
//   object-fit: cover;
//   // z-index: 10;
//   background-color: black;
//   background-image:
//     linear-gradient(270deg, rgba(0, 0, 0, 0) 10rem, rgba(0, 0, 0, 1) 45rem) ,
//     linear-gradient(270deg, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 20rem),
//     url("../images/Maze3.png")
//   ;
//   background-repeat: no-repeat;
//   padding: 6rem;
//   // background-position-y: bottom;
//   // background-position-x: right;
// }

.block1 {
  grid-column: 1 / 3;
  background-size: auto auto;
  color: white;
  margin-top: 3rem;
  object-fit: cover;
  background-position: right;
  background-color: black;
  background-image: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 10rem,
      rgba(0, 0, 0, 1) 45rem
    ),
    url("../images/Maze3.png");
  background-repeat: no-repeat;
  padding: 6rem;
  max-height: 42rem;
}

.heroHeader {
  max-width: 30ch;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
  padding-bottom: 5rem;
  text-decoration: to-upper-case($string: "");
}
.heroText {
  color: white;
  max-width: 35ch;
  padding-bottom: 5rem;
}

.demoButton {
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  margin-right: 2rem;
  margin-top: 2rem;
}

.demoButton:hover {
  border: solid 1px #0ba5ec;
}

.whyFesButton {
  width: 11rem;
  // height: 2rem;
  font-size: 1.25rem;
  background-color: black;
  color: white;
  border: gray 1px solid;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  margin-right: 2rem;
  margin-top: 2rem;
}

.whyFesButton:hover {
  border: solid 1px #0ba5ec;
}

//Block2//

.block2Left {
  display: grid;
  grid-column: 1 / 2;
  background-color: white;
  justify-content: center;
  align-content: center;
  color: black;
  margin: auto;
  width: 100%;
  padding: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.block2Image {
  max-width: 40rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid black;
  padding: 2rem;
}

.block2right {
  display: grid;
  grid-column: 2 / 3;
  background-color: white;
  color: black;
  margin: auto;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  // padding-left: 3rem;
}

.block2RightHeader {
  font-family: sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  color: black;
  max-width: 22ch;
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.emphasisUnderline {
  background-image: linear-gradient(
    90deg,
    rgba(14, 146, 220, 1),
    rgba(14, 146, 220, 0.15)
  );
  background-size: 100% 4px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding-bottom: 2px; /* Pull underline down away from bottom of text */
}

/* Change underline color/opacity when parent element is being hovered */
*:hover > .emphasisUnderline {
  background-image: linear-gradient(
    90deg,
    rgb(14, 130, 255, 1),
    rgba(14, 52, 255, 0.15)
  );
}

.block2RightTextWrapper {
  display: flex;
  flex-direction: row;
  font-size: 0.85rem;
  // padding-bottom: .5rem;
}

.homeIcon {
  width: 15px;
  padding-bottom: 1rem;
}

.block2RightText {
  max-width: 50ch;
  padding-left: 1rem;
}

//Block3//

.block3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr;
  justify-content: space-around;
  grid-column: 1 / 3;
  object-fit: cover;
  color: white;
  background-image: url("../images/block3.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding-bottom: 6rem;
}

.whyFesHeader {
  display: grid;
  grid-row: 1/ 2;
  grid-column: 1 / 4;
  align-self: center;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.whyFesItemHeader {
  padding-bottom: 1.5rem;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
  font-size: 1.2rem;
}

.whyFesText {
  padding-bottom: 0.75rem;
  font-size: 1rem;
}

.whyFesItemWrapper {
  background-color: #23282b;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 20rem;
  height: 23rem;
  text-align: center;
  align-items: center;
  padding: 1rem;
  opacity: 0.8;
}

.whyFesItemWrapper1 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.whyFesItemWrapper2 {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.whyFesItemWrapper3 {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

//Block4//

.block4Left {
  display: grid;
  grid-column: 1 / 2;
  background-color: white;
  color: black;
  margin: auto;
  justify-content: center;
  align-content: center;
  width: 100%;

  height: 100%;
  padding: 3rem;
}

// .block4SwiperContainer {
//   margin: auto;
//   padding-right: 2rem;
//   // width: 100%;

// }

// .swiperSlide {
//   width: 100%;
//   margin-right: 0;
// }

.block4LeftImage {
  // width: 100%;
  // background-color: rgba(0, 0, 0, 0);
  // object-fit: cover;
  // width: 38rem;
  max-width: 36rem;
}

.block4LeftImageWrapper {
  // width: 100%;
  // background-color: rgba(0, 0, 0, 0);
}

.hidden {
  display: none;
}

.block4Right {
  display: grid;
  grid-column: 2 / 3;
  background-color: white;
  color: black;
  margin: auto;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem;
  padding-left: 3rem;
}

.block4RightHeader {
  font-family: sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  color: black;
  padding-left: 4.85rem;
  padding-bottom: 2rem;
  font-weight: bold;
}

.featureEmphasisUnderline {
  display: inline-block;
  background-image: linear-gradient(
    90deg,
    rgba(14, 146, 220, 1),
    rgba(14, 146, 220, 0.15)
  );
  background-size: 100% 4px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding-bottom: 2px;
  width: 15rem;
  /* Pull underline down away from bottom of text */
}

.block4RightWrapper {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.block4RightTextWrapper {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  padding-bottom: 0rem;
}

.block4RightTextWrapper:hover {
  background-color: rgb(244, 244, 244);
  background-image: linear-gradient(
    90deg,
    rgba(14, 146, 220, 1),
    rgba(14, 146, 220, 0.15),
    white,
    white
  );
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding-bottom: 1px;
  /* Pull underline down away from bottom of text */
}

.block4FeatureWrapper {
  padding-left: 3rem;
  color: black;
}

.block4FeatureHeader {
  font-size: 1.25rem;
  font-weight: bold;
}

.fesImage {
  width: 25px;
  // padding-bottom: 6.25rem;
}

//Block 5//

.block5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr;
  justify-content: space-around;
  grid-column: 1 / 3;
  object-fit: cover;
  color: white;
  background-size: cover;
  text-align: center;
  padding-bottom: 6rem;
}

.block5Header {
  display: grid;
  grid-row: 1/ 2;
  grid-column: 1 / 4;
  align-self: center;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.block5ItemHeader {
  padding-bottom: 1rem;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.block5Text {
  // padding-bottom: 0.75rem;
  text-align: left;
  font-size: 1.35rem;
  // text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.block5ItemWrapper {
  background-color: black;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.21),
    rgba(255, 255, 255, 0.074)
  );
  color: white;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 20rem;
  height: 23rem;
  text-align: center;
  align-items: center;
  padding: 1rem;
  border: 8px solid rgb(23, 23, 23);
}

.block5TextWrapper {
  text-align: left;
}

.block5ItemWrapper1 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.block5ItemWrapper2 {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.block5ItemWrapper3 {
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

.clientsImage {
  width: 275px;
  padding-bottom: 2rem;
}

.block5IconWrapper {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding-bottom: 1rem;
  // align-items: center;
  //   justify-content: center;
}

.homeIconDark {
  height: 15px;
  padding-right: 1rem;
  padding-top: 4px;
}

.clientsText {
  font-size: 0.85rem;
}

//Block6//

.block6 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
}

.block6Right {
  display: grid;
  grid-column: 2 / 3;
  background-color: white;
  color: black;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-bottom: 2rem;
  justify-content: center;
  align-content: center;
  // padding-left: 0;
  img {
    width: 100%;
  }
}

.block6Left {
  display: grid;
  grid-column: 1 / 2;
  background-color: white;
  color: black;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-bottom: 2rem;
}

.block6DemoButton {
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: 2rem auto auto 0;
  // margin-top: 2rem;
  justify-self: left;
}

.block6Header {
  max-width: 30ch;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 2rem;
}

.scrollOffset5 {
  scroll-margin-top: 5rem;
}

//**************************************************//

@media only screen and (max-width: 600px) {
  //Block1Mobile//

  .block1 {
    grid-column: 1 / 3;
    background-size: cover;
    background-image: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0) 0rem,
        rgba(0, 0, 0, 1) 10rem
      ),
      url("../images/Maze3.png");
    background-repeat: no-repeat;
    padding: 2rem;
    width: 100vw;
  }

  .heroHeader {
    font-size: 1rem;
    padding-bottom: 2rem;
  }

  .heroText {
    font-size: 0.85rem;
    padding-bottom: 2rem;
  }

  .demoButton {
    width: 7rem;
    font-size: 0.85rem;
  }

  .whyFesButton {
    width: 7rem;
    font-size: 0.85rem;
  }

  //Block2Mobile//

  .block2Left {
    display: grid;
    grid-column: 1 / 3;
    background-color: white;
    background-size: 100% 100%;
    justify-content: center;
    align-content: center;
    color: black;
    margin: auto;
    width: 100vw;
    padding: 0;
    margin-bottom: -2rem;
  }

  .block2Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .block2right {
    display: grid;
    grid-column: 1 / 3;
    background-color: white;
    color: black;
    margin: auto;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    // padding-left: 3rem;
  }

  .block2RightHeader {
    font-family: sans-serif;
    font-size: 1rem;
    font-weight: bold;
    color: black;
    max-width: 22ch;
    padding-left: 2rem;
    padding-bottom: 1rem;
  }

  .emphasisUnderline {
    background-image: linear-gradient(
      90deg,
      rgba(14, 146, 220, 1),
      rgba(14, 146, 220, 0.15)
    );
    background-size: 100% 4px;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-bottom: 2px;
    /* Pull underline down away from bottom of text */
  }

  /* Change underline color/opacity when parent element is being hovered */
  *:hover > .emphasisUnderline {
    background-image: linear-gradient(
      90deg,
      rgb(14, 130, 255, 1),
      rgba(14, 52, 255, 0.15)
    );
  }

  .block2RightTextWrapper {
    display: flex;
    flex-direction: row;
    // padding-bottom: .5rem;
  }

  .homeIcon {
    width: 15px;
    padding-bottom: 1rem;
  }

  .block2RightText {
    max-width: 40ch;
    padding-left: 1rem;
    font-size: 0.75rem;
  }

  //Block3Mobile//

  .block3 {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 3;
    object-fit: cover;
    color: white;
    //
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding-bottom: 2rem;
    width: 100vw;
  }

  .whyFesHeader {
    display: grid;
    grid-row: 1/ 2;
    // grid-column: 1 / 4;
    align-self: center;
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .whyFesItemHeader {
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
    font-size: 1rem;
    padding-bottom: 0;
  }

  .whyFesText {
    // padding-bottom: 0.75rem;
    font-size: 0.85rem;
    padding-bottom: 0;
  }

  .whyFesItemWrapper {
    background-color: #23282b;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 17rem;
    height: 22rem;
    text-align: center;
    align-items: center;
    padding: 1rem;
    padding-bottom: 0;
    opacity: 0.8;
    margin-bottom: 2rem;
  }

  .whyFesItemWrapper1 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .whyFesItemWrapper2 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .whyFesItemWrapper3 {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .homeIconBlock3 {
    width: 15px;
    // padding-bottom: 1rem;
  }

  //Block4Moblie//

  .block4Left {
    display: grid;
    grid-column: 1 / 3;
    background-color: white;
    color: black;
    // margin: auto;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    // padding: 3rem;
  }

  .block4Right {
    display: grid;
    grid-column: 1 / 3;
    background-color: white;
    color: black;
    margin: auto;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    // padding-left: 3rem;
  }

  .block4RightHeader {
    font-family: sans-serif;
    font-size: 1.75rem;
    font-weight: bold;
    color: black;
    padding-left: 4.85rem;
    padding-bottom: 2rem;
    font-weight: bold;
  }

  .featureEmphasisUnderline {
    display: inline-block;
    background-image: linear-gradient(
      90deg,
      rgba(14, 146, 220, 1),
      rgba(14, 146, 220, 0.15)
    );
    background-size: 100% 4px;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-bottom: 2px;
    width: 11rem;
    /* Pull underline down away from bottom of text */
  }

  .block4RightWrapper {
    display: flex;
    flex-direction: column;
    max-width: 30rem;
  }

  .block4RightTextWrapper {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    padding-bottom: 0rem;
  }

  .block4RightTextWrapper:hover {
    background-color: rgb(244, 244, 244);
    background-image: linear-gradient(
      90deg,
      rgba(14, 146, 220, 1),
      rgba(14, 146, 220, 0.15),
      white,
      white
    );
    background-size: 100% 2px;
    background-repeat: no-repeat;
    background-position: left top;
    padding-bottom: 1px;
    /* Pull underline down away from bottom of text */
  }

  .block4FeatureWrapper {
    padding-left: 3rem;
  }

  .block4FeatureHeader {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .fesImage {
    width: 2rem;
    padding-bottom: 0;
  }

  .block4LeftImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // margin: auto;
    padding-left: 0;
  }

  //Block 5//

  .block5 {
    display: flex;
    flex-direction: column;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-template-rows: 0.5fr 1fr;
    justify-content: space-around;
    grid-column: 1 / 3;
    object-fit: cover;
    color: white;
    background-size: cover;
    text-align: center;
    padding-bottom: 6rem;
  }

  .block5Header {
    // display: grid;
    // grid-row: 1/ 2;
    // grid-column: 1 / 4;
    align-self: center;
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
    margin-top: 2rem;
  }

  .block5ItemHeader {
    padding-bottom: 1rem;
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
  }

  .block5Text {
    padding-bottom: 0.75rem;
    text-align: left;
    font-size: 1.35rem;
    // text-shadow: 0px 1px, 1px 0px, 1px 0px;
  }

  .block5ItemWrapper {
    background-color: black;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.21),
      rgba(255, 255, 255, 0.074)
    );
    color: white;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    height: 23rem;
    text-align: center;
    align-items: center;
    padding: 1rem;
    border: 8px solid rgb(23, 23, 23);
    margin-bottom: 2rem;
  }

  .block5TextWrapper {
    text-align: left;
  }

  .block5ItemWrapper1 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .block5ItemWrapper2 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .block5ItemWrapper3 {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .clientsImage {
    width: 275px;
    padding-bottom: 2rem;
  }

  .block5IconWrapper {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .homeIconDark {
    height: 15px;
    padding-right: 1rem;
  }

  .clientsText {
    font-size: 0.85rem;
  }

  //Block6Mobile//

  .block6 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-height: 62rem;
  }

  .block6Right {
    display: grid;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    background-color: white;
    color: black;
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 3rem;
    margin-bottom: 0;
    padding-bottom: 0;
    justify-content: center;
    align-content: center;

    // padding-left: 0;
    img {
      width: 100%;
    }
  }

  .block6Left {
    display: grid;
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    background-color: white;
    color: black;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 3rem;
    margin-bottom: 2rem;
    padding-bottom: 0;
    padding-top: 0;
  }

  .block6DemoButton {
    width: 11rem;
    font-size: 1.25rem;
    background-color: #0ba5ec;
    color: white;
    border: none;
    font-weight: bold;
    padding: 0.25rem;
    margin: 2rem auto auto 0;
    // margin-top: 2rem;
    justify-self: left;
  }

  .block6Header {
    max-width: 30ch;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 2rem;
  }

  .demoButtonConstant {
    right: 0;
    top: 185px;
    width: 8rem;
    font-size: 1rem;
    margin-right: -3.4rem;
  }

  

  .hubspotForm {
    grid-column: 1 / 2;
    min-width: none;
    min-height: none;
    background-image: none;
    padding-left: 2rem;
  }

  
}
