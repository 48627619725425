@import "variables";

$learnitwidth: 70%;

#learnIt {
  margin-top: 3.5rem;
  font-family: sans-serif;
  color: white;
  font-size: 2rem;
  padding-bottom: 1rem;
  background-color: rgb(218, 217, 217);
}

.learnItGrid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 25px;
  padding: 50px 25px 25px 25px;
  margin: auto;
}

.learningItHeader {
  color: black;
  padding-left: 3rem;
  padding-top: 1rem;
}

.learnItTopic {
  font-size: 0.9rem;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.learnItTitle {
  font-family: sans-serif;
  font-size: 26pt;
  height: 45px;
  width: 100%;
  font-size: 0.9rem;
  color: #697586;
  font-weight: 900;
  padding-left: 3rem;
}

.learningItNavBar {
  color: #697586;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1rem;
  text-decoration: none;
  border: none;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.learningItNavBarItem {
  all: unset;
  color: #697586;
  border: 1px solid transparent;
  background-color: rgb(207, 207, 207);
  border-radius: 0.25rem;
  padding: 0.25rem;
  &:hover {
    color: black;
    cursor: pointer;
    border: 1px solid black;
  }
}

.learningItNavBarItemHighlighted {
  color: black;
  border: 1px solid darkgray;
  &:hover {
    border: 1px solid black;
  }
}

hr {
  border-top: solid darkgray 2px;
  width: 100%;
}

.learnItTopicWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 15rem;
  width: 15rem;
  padding: 15px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  text-align: center;
  color: black;
  background-color: white;
  text-align: left;
  margin: auto;
}

.learnItTopicWrapper:hover {
  border: solid black 1px;
}

.learnItImage {
  margin-bottom: 1rem;
  height: 110px;
}

.learnItText {
  font-size: 0.75rem;
  color: #697586;
}

a {
  color: #697586;
  cursor: pointer;
}

//Learning It Articles//

.learnItArticle {
  width: $learnitwidth;
  display: grid;
  padding: 25px 40px 40px 60px;
}

.learnItArticleTitle {
  background-color: rgb(218, 217, 217);
  width: 100%;
  margin-bottom: 2rem;
}

.learnItArticleText {
  padding-left: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}

.learnItArticleImagesWrapper {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: grid;
  justify-self: start;
  margin: auto;
  justify-content: center;
}

.learningItArticleImage {
  max-width: 18rem;
  padding-top: 1rem;
}

.learnItParagraph {
  padding-bottom: 30px;
  margin-left: 4.5rem;
  color: #697586;
  font-size: 0.85rem;
}

.learnItIcon {
  width: 50px;
  height: 50px;
  background-color: $FFblu2;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 0.5em;
  fill: currentColor;
  color: white;
}

.learnItArticleContent {
  display: grid;
  grid-template-columns: 1fr 0.25fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 12pt;
  background-color: white;
  padding-top: 75px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
}

.articleTextWrapper {
  grid-column: 1 / 2;
  display: grid;
  grid-row: 1 / 2;
}

.learnItSubHeading {
  color: black;
  position: relative;
  padding-bottom: 15px;
  padding-left: 75px;
}

.learnItArticleImage {
  width: 300px;
  margin: 0 auto;
  font-size: 1rem;
}

.learnItBackButton {
  all: unset;
  color: #697586;
  font-size: 1rem;
  margin-bottom: 1rem;
  padding-left: 3rem;
}

.learnItBackButton:hover {
  color: black;
  text-decoration: underline;
}

.trainingTopic {
  border-top-right-radius: 25px;
  height: 120px;
  padding: 10px;
  cursor: pointer;
  margin: 2rem 0rem;
}

.trainingTopicTitle {
  color: $FFblu1;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1.5rem;
}

.trainingTopicTitle:hover {
  color: $FFblu2;
}

.trainingImage {
  border-top-right-radius: 25px;
  height: 120px;
  width: 200px;
  margin-left: -10px;
  margin-top: -10px;
}

.feedback {
  margin: 0 auto;
  background-color: $FFdkbeige;
  font-weight: bold;
  font-family: $open-sans;
  font-size: 14pt;
  color: $FFdkdkbeige;
  width: 600px;
  text-align: center;
  padding-bottom: 50px;
  display: none;
}

//**************************************************//

@media (max-width: 1170px) {
  .learnItGrid {
    grid-template-columns: 1fr 1fr;
  }

  .learningItNavBar {
    font-size: 0.85rem;
  }
}

//**************************************************//

@media (max-width: 600px) {
  #learnIt {
    // max-width: unset;
    // margin: auto;
    margin-top: 2.25rem;
    font-family: sans-serif;
    color: white;
    font-size: 2rem;
    padding: 1rem;
    // padding-top: 3rem;
    // position: relative;
    // padding-bottom: 100px;
    background-color: rgb(218, 217, 217);
  }

  .learnItGrid {
    display: grid;
    width: auto;
    grid-template-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 25px;
    padding: 25px 25px 25px 25px;
    margin: 0 auto;
  }

  .learnItArticleContent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1fr;
    grid-gap: 1rem;
    padding-top: 0;
  }

  .articleTextWrapper {
    grid-column: 1 / 2;
    display: grid;
    grid-row: 2 / 3;
  }

  .learningItHeader {
    color: black;
    padding-left: 3rem;
    padding-top: 2rem;
  }

  .learnItArticleImagesWrapper {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: grid;
    width: 18rem;
    margin: auto;
  }

  .learnItSubHeading {
    color: black;
    margin-left: 0;
    padding-left: 0;
  }

  .learnItParagraph {
    margin-left: 0;
    padding-left: 0;
    padding-bottom: 30px;
    color: #697586;
    font-size: 0.85rem;
  }

  .learnItBackButton {
    all: unset;
    color: #697586;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding-left: 0;
  }

  .learnItArticleText {
    padding-left: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
  }
}
