.clientsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  font-family: sans-serif;
  background-color: black;
}

//Block1//

.block1 {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  width: 100%;
  height: 100%;
  height: 45rem;
  background-color: black;
  margin-top: 3rem;
  text-align: left;
}

.block1Left {
  grid-column: 1 / 2;
  padding: 4rem;
}

.block1Right {
  grid-column: 2 / 3;
  width: 100%;
  background-image: url("../images/Elipsis.svg");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  margin-top: -10rem;
  margin-right: -5rem;
}

.productImage1 {
  position: absolute;
  top: 200px;
  left: 550px;
  // width: 35rem;
}

.productImage2 {
  position: absolute;
  top: 250px;
  left: 775px;
  rotate: -10deg;
  // width: 35rem;
}

.block1Header {
  padding-top: 4rem;
  max-width: 6ch;
  font-weight: 900;
  font-size: 3rem;
  text-shadow: 0px 1px, 1px 0px, 1px 0px;
}

.block1Text {
  max-width: 45ch;
  padding-top: 4rem;
  font-size: 1rem;
}

.demoButton {
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  margin-top: 4rem;
}

.anchor:hover {
  text-decoration: none;
  cursor: pointer;
}

.anchor {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

.elipsis {
  margin: auto;
  opacity: 0.7;
}

//Block2//

.block2 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
  min-height: 40rem;
  background-color: white;
  color: black;
  width: 100%;
}

.block2Left {
  grid-column: 1 / 2;
  margin: auto;
  padding: 3rem;
}

.block2LeftImage {
  min-width: 40rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.block2Right {
  grid-column: 2 / 3;
  margin: auto;
}

.block2TextWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  width: 100%;
}

.block2RightHeader {
  font-weight: bolder;
  padding-bottom: 3rem;
}

.block2RightText {
  font-size: 0.85rem;
  max-width: 65ch;
  margin-bottom: 0;
}

//Block3//

.block3 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
  min-height: 40rem;
  background: rgb(0, 0, 5);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 5, 1) 0%,
    rgba(12, 12, 19, 1) 35%,
    rgba(107, 107, 107, 1) 100%
  );
  background-repeat: no-repeat;
  width: 100%;
}

.block3Right {
  grid-column: 2 / 3;
  margin: auto;
  padding: 3rem;
}

.block3RightImage {
  max-width: 40rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.block3Left {
  grid-column: 1 / 2;
  margin: auto;
  width: 100%;
  padding: 3rem;
}

.block3LeftTextWrapper {
  display: flex;
  flex-direction: row;
  color: white;
  margin: auto;
  align-items: start;
}

.block3LeftHeader {
  font-weight: bolder;
  padding-bottom: 3rem;
  color: white;
}

.block3LeftText {
  font-size: 1rem;
  max-width: 50ch;
}

.homeIcon {
  padding-top: 0.25rem;
  padding-right: 1rem;
}

//Block4//

.block4 {
  display: grid;
  grid-column: 1 / 3;
  grid-template-columns: 1fr 1fr;
  min-height: 40rem;
  background-color: white;
  width: 100%;
}

.block4Left {
  grid-column: 1 / 2;
  margin: auto;
}

.block4LeftImage {
  min-width: 40rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.block4Right {
  grid-column: 2 / 3;
  margin: auto;
  color: black;
}

.block4RightHeader {
  font-weight: bolder;
  padding-bottom: 3rem;
}

.block4RightText {
  font-size: 0.85rem;
  max-width: 65ch;
}

.block4TextWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  min-width: 25rem;
}

.block4HomeIcon {
  padding-right: 1rem;
}

////////
.scrollOffset5 {
  scroll-margin-top: 5rem;
}

.demoButtonConstant {
  position: fixed;
  right: 0;
  top: 185px;
  width: 11rem;
  font-size: 1.25rem;
  background-color: #0ba5ec;
  color: white;
  border: none;
  font-weight: bold;
  padding: 0.25rem;
  margin: auto;
  transform: rotate(270deg);
  margin-right: -4.3rem;
}

@media only screen and (max-width: 600px) {
  //Block1Mobile//

  .block1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    width: 100%;
    height: 100%;
    height: 38rem;
    background-color: black;
    margin-top: -1rem;
    text-align: left;
    margin-bottom: 1rem;
  }

  .block1Left {
    grid-row: 1 / 2;
    margin-bottom: 5rem;
    padding-bottom: 0;
    padding-top: 0;
    height: 0rem;
  }

  .block1Right {
    display: grid;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding-bottom: 0;
    margin-top: -10rem;
    margin-right: -5rem;
  }

  .productImage1 {
    position: absolute;
    top: 350px;
    left: 60px;
    width: 15rem;
  }

  .productImage2 {
    position: absolute;
    top: 400px;
    left: 95px;
    rotate: -10deg;
    width: 15rem;
  }

  .block1Header {
    font-size: 1.5rem;
  }

  .block1Text {
    padding-top: 1rem;
    font-size: 0.85rem;
  }

  .block1Text2 {
    padding-top: 0;
    font-size: 0.85rem;
  }

  .demoButton {
    width: 8rem;
    font-size: 1rem;
    margin-top: 2rem;
    z-index: 99999;
    display: none;
  }

  //Block2Mobile//

  .block2 {
    display: grid;
    grid-column: 1 / 3;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    min-height: 35rem;
    background-color: white;
    color: black;
    width: 100%;
  }

  .block2Left {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    margin: auto;
  }

  .block2LeftImage {
    min-width: 15rem;
    max-width: 18rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .block2Right {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    margin: auto;
    padding: auto;
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .block2RightHeader {
    text-align: center;
    font-weight: bolder;
    padding-bottom: 1.5rem;
    font-size: 1.25rem;
  }

  .block2RightText {
    font-size: 0.85rem;
    max-width: 40ch;
  }

  .block2TextWrapper {
    padding: 0 2.5rem 1rem 2.5rem;
  }

  //Block3Mobile//

  .block3 {
    display: grid;
    grid-column: 1 / 3;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 42.5rem;
    background: rgb(0, 0, 5);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 5, 1) 0%,
      rgba(12, 12, 19, 1) 35%,
      rgba(107, 107, 107, 1) 100%
    );
    background-repeat: no-repeat;
    width: 100%;
    margin: auto;
  }

  .block3Right {
    display: grid;
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    justify-content: center;
    align-items: center;
  }

  .block3RightImage {
    min-width: 15rem;
    max-width: 18rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .block3Left {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    margin: auto;
    width: 100%;
    padding: 1rem;
  }

  .block3LeftTextWrapper {
    display: flex;
    flex-direction: row;
    color: white;
    margin: auto;
    align-items: start;
    padding: 0 2.5rem ;
  }

  .block3LeftHeader {
    font-weight: bolder;
    padding-bottom: 2rem;
    color: white;
    font-size: 1.25rem;
    text-align: center;
  }

  .block3LeftText {
    font-size: 0.85rem;
    max-width: 50ch;
  }

  .homeIcon {
    padding-top: 0.25rem;
    padding-right: 1rem;
  }

  //Block4Mobile//

  .block4 {
    display: grid;
    grid-column: 1 / 3;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    min-height: 40rem;
    background-color: white;
    width: 100%;
    margin: auto;
  }

  .block4Left {
    grid-column: 1 / 3;
    grid-row: 1, 2;
    margin: auto;
  }

  .block4LeftImage {
    min-width: 20rem;
    max-width: 20rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .block4Right {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    margin: auto;
    color: black;
    padding: 1rem auto;
  }

  .block4RightHeader {
    text-align: center;
    font-weight: bolder;
    padding-bottom: 1.5rem;
    font-size: 1.25rem;
  }
  .block4TextWrapper {
    display: flex;
    flex-direction: row;
  }

  .block4RightText {
    font-size: 0.85rem;
    max-width: 65ch;
  }

  .demoButtonConstant {
    width: 11rem;
    font-size: 0.9rem;
  }
}
